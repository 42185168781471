import { connect, StringCodec } from "nats.ws";
var nats_msg_client = {
    serverUrl: "",
    token: "",
    isConnected: false,
    channels: {},
    client: undefined,
    stringCodec: StringCodec(),
    OnReceiveMsg: function(raw) {
        let data = JSON.parse(raw);
        if (data && data.channelId) {
            nats_msg_client.channels[data.channelId].onMsg(data);
        }
    },
    Connect: async function(serverUrl, token, channels, onReceiveMsg, callback) {
        if (!serverUrl) {
            alert("参数错误，请提供正确的Nats Server地址(ws://xxxxx:4223)");
        } else {
            if (!nats_msg_client.isConnected) {
                nats_msg_client.serverUrl = serverUrl;
                nats_msg_client.token = token;
                nats_msg_client.client = await connect({
                    servers: serverUrl,
                    token: token
                });
                nats_msg_client.isConnected = typeof nats_msg_client.client !== "undefined";
            }
            if (nats_msg_client.isConnected) {
                var _channels = [];
                if (typeof(channels) === "string") {
                    _channels.push(channels);
                } else if (channels instanceof Array) {
                    _channels = _channels.concat(channels);
                };
                _channels.forEach(channel => {
                    if (typeof(nats_msg_client.channels[channel]) === "undefined") {
                        nats_msg_client.channels[channel] = {
                            onMsg: onReceiveMsg
                        };
                        const sub = nats_msg_client.client.subscribe(channel);
                        (async () => {
                            for await (const m of sub) {
                                nats_msg_client.OnReceiveMsg(nats_msg_client.stringCodec.decode(m.data));
                            }
                        })();
                    } else {
                        console.log("请勿重复订阅频道(" + channel + ")");
                    }
                });
                if (typeof callback === "function") {
                    callback();
                }
            }
        }
    },
    CloseChannel: function(channel) {
        if (nats_msg_client.isConnected) {
            nats_msg_client.client.unsubscribe(channel);
            delete(nats_msg_client.channels[channel]);
        } else {
            console.log("尚未连接Nats服务器，请确保OpenChannel方法被正确调用了");
        }
    },
    SendMsg: function(channel, msgType, msg) {
        if (channel) {
            if (nats_msg_client.isConnected) {
                nats_msg_client.client.publish(channel, JSON.stringify({
                    channelId: channel,
                    msgType: msgType,
                    data: msg
                }));
            } else {
                console.log("尚未连接Nats服务器，请确保OpenChannel方法被正确调用了");
            }
        } else {
            console.log("SendMsg必须传入channel参数");
        }
    }
}
export default nats_msg_client;