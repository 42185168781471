// 统一请求路径前缀在libs/axios.js中修改
import {
    getRequest,
    postRequest,
    putRequest,
    postBodyRequest,
    getNoAuthRequest,
    postNoAuthRequest
} from '@/libs/axios';


// Vaptcha ID
export const vaptchaID = ""
// Vaptcha离线验证接口
export const vaptchaOffline = `${import.meta.env.VITE_BASE_API}/common/vaptcha/offline`
// 文件上传接口
export const uploadFile = `${import.meta.env.VITE_BASE_API}/common/upload/file`
// 验证码渲染图片接口
export const drawCodeImage = `${import.meta.env.VITE_BASE_API}/common/captcha/draw/`
// 获取数据字典
export const getDictData = `${import.meta.env.VITE_BASE_API}/common/dict/getDataByType/`
// Websocket
export const ws = `${import.meta.env.VITE_BASE_API}/ws`
// 文章栏目接口
export const getCatalogListURL = `/manage/catalog/getByParentId/`

// 获取用户菜单
export const getMenuList = (params) => {
    return getRequest('/common/permission/getMenuList', params)
}

// 登陆
export const login = (params) => {
    return postNoAuthRequest('/common/user/login', params)
}
// 获取用户登录信息
export const userInfo = (params) => {
    return getRequest('/common/user/info', params)
}
// 注册
export const regist = (params) => {
    return postNoAuthRequest('/common/user/regist', params)
}
// 初始化验证码
export const initCaptcha = (params) => {
    return getNoAuthRequest('/common/captcha/init', params)
}
// 发送登录短信验证码
export const sendLoginSms = (mobile, params) => {
    return getNoAuthRequest(`/common/captcha/sendLoginSms/${mobile}`, params)
}
// 发送注册短信验证码
export const sendRegistSms = (mobile, params) => {
    return getNoAuthRequest(`/common/captcha/sendRegistSms/${mobile}`, params)
}
// 发送重置密码短信验证码
export const sendResetSms = (mobile, params) => {
    return getNoAuthRequest(`/common/captcha/sendResetSms/${mobile}`, params)
}
// 发送修改绑定手机短信验证码
export const sendEditMobileSms = (mobile, params) => {
    return getRequest(`/common/captcha/sendEditMobileSms/${mobile}`, params)
}
// 通过手机重置密码
export const resetByMobile = (params) => {
    return postNoAuthRequest('/common/user/resetByMobile', params)
}
// 发送重置密码邮件验证码
export const sendResetEmail = (email, params) => {
    return getNoAuthRequest(`/email/sendResetCode/${email}`, params)
}
// 发送修改绑定邮件验证码
export const sendEditEmail = (email, params) => {
    return getRequest(`/email/sendEditCode/${email}`, params)
}
// 通过邮件重置密码
export const resetByEmail = (params) => {
    return postNoAuthRequest('/email/resetByEmail', params)
}
// 短信验证码登录
export const smsLogin = (params) => {
    return postRequest('/user/smsLogin', params)
}
// IP天气信息
export const ipInfo = (params) => {
    return getRequest('/common/ip/info', params)
}
// 个人中心编辑
export const userInfoEdit = (params) => {
    return postBodyRequest('/manage/user/edit', params)
}
// 个人中心发送修改邮箱验证邮件
export const sendCodeEmail = (email, params) => {
    return getRequest(`/email/sendCode/${email}`, params)
}
// 个人中心发送修改邮箱验证邮件
export const editEmail = (params) => {
    return postRequest('/email/editEmail', params)
}
// 个人中心修改密码
export const changePass = (params) => {
    return postRequest('/manage/user/modifyPass', params)
}
// 个人中心修改手机
export const changeMobile = (params) => {
    return postRequest('/user/changeMobile', params)
}
// 解锁
export const unlock = (params) => {
    return postRequest('/common/user/unlock', params)
}
// 获取公告
export const getNotice = (params) => {
    return getNoAuthRequest('/common/setting/notice', params)
}

// 获取CAS登录信息
export const getCasToken = (authId) => {
    return getNoAuthRequest('/common/user/getCasToken', { authId: authId })
}



// github登录
export const githubLogin = (params) => {
    return getNoAuthRequest('/social/github/login', params)
}
// qq登录
export const qqLogin = (params) => {
    return getNoAuthRequest('/social/qq/login', params)
}
// 微博登录
export const weiboLogin = (params) => {
    return getNoAuthRequest('/social/weibo/login', params)
}
// 微信登录
export const wechatLogin = (params) => {
    return getNoAuthRequest('/social/wechat/login', params)
}
// 钉钉登录
export const dingdingLogin = (params) => {
    return getNoAuthRequest('/social/dingding/login', params)
}
// 企业微信登录
export const workwechatLogin = (params) => {
    return getNoAuthRequest('/social/workwechat/login', params)
}
// 绑定账号
export const relate = (params) => {
    return postRequest('/social/relate', params)
}
// 获取JWT
export const getJWT = (params) => {
    return getNoAuthRequest('/social/getJWT', params)
}



// 获取绑定账号信息
export const relatedInfo = (username, params) => {
    return getRequest(`/relate/getRelatedInfo/${username}`, params)
}
// 解绑账号
export const unRelate = (params) => {
    return postRequest('/relate/delByIds', params)
}
// 分页获取绑定账号信息
export const getRelatedListData = (params) => {
    return getRequest('/relate/findByCondition', params)
}



// 获取用户数据 多条件
export const getUserListData = (params) => {
    return postBodyRequest('/manage/user/getByCondition', params)
}
// 通过用户名搜索
export const searchUserByName = (username, params) => {
    return getRequest('/manage/user/searchByName/' + username, params)
}
// 获取全部用户数据
export const getAllUserData = (params) => {
    return getRequest('/manage/user/getAll', params)
}
// 通过部门获取全部用户数据
export const getUserByDepartmentId = (id, params) => {
    return getRequest(`/manage/user/getByDepartmentId/${id}`, params)
}
// 添加用户
export const addUser = (params) => {
    return postBodyRequest('/manage/user/add', params)
}
// 编辑用户
export const editUser = (params) => {
    return postBodyRequest('/manage/user/edit', params)
}
// 启用用户
export const enableUser = (id, params) => {
    return postRequest(`/manage/user/enable/${id}`, params)
}
// 禁用用户
export const disableUser = (id, params) => {
    return postRequest(`/manage/user/disable/${id}`, params)
}
// 删除用户
export const deleteUser = (params) => {
    return postRequest('/manage/user/delByIds', params)
}
// 导入用户
export const importUserData = (params) => {
    return postBodyRequest('/manage/user/importData', params)
}
// 重置用户密码
export const resetUserPass = (params) => {
    return postRequest('/manage/user/resetPass', params)
}



// 获取一级部门
export const initDepartment = (params) => {
    return getRequest('/manage/department/getByParentId/0', params)
}
// 加载部门子级数据
export const loadDepartment = (id, params) => {
    return getRequest(`/manage/department/getByParentId/${id}`, params)
}
// 添加部门
export const addDepartment = (params) => {
    return postBodyRequest('/manage/department/add', params)
}
// 编辑部门
export const editDepartment = (params) => {
    return postBodyRequest('/manage/department/edit', params)
}
// 删除部门
export const deleteDepartment = (params) => {
    return postRequest('/manage/department/delByIds', params)
}
// 搜索部门
export const searchDepartment = (params) => {
    return getRequest('/manage/department/search', params)
}



// 获取全部角色数据
export const getAllRoleList = (params) => {
    return getRequest('/manage/role/getAll', params)
}
// 分页获取角色数据
export const getRoleList = (params) => {
    return postBodyRequest('/manage/role/getAllByPage', params)
}
// 添加角色
export const addRole = (params) => {
    return postBodyRequest('/manage/role/add', params)
}
// 编辑角色
export const editRole = (params) => {
    return postBodyRequest('/manage/role/edit', params)
}
// 设为或取消注册角色
export const setDefaultRole = (params) => {
    return postBodyRequest('/manage/role/setDefault', params)
}
// 分配角色权限
export const editRolePerm = (params) => {
    return postBodyRequest('/manage/role/editRolePerm', params)
}
// 分配角色数据权限
export const editRoleDep = (params) => {
    return postBodyRequest('/manage/role/editRoleDep', params)
}
// 删除角色
export const deleteRole = (params) => {
    return postRequest('/manage/role/delByIds', params)
}



// 获取全部权限数据
export const getAllPermissionList = (params) => {
    return getRequest('/manage/permission/getAll', params)
}
// 加载数据
export const loadPermission = (id, params) => {
    return getRequest(`/manage/permission/getByParentId/${id}`, params)
}
// 添加权限
export const addPermission = (params) => {
    return postBodyRequest('/manage/permission/add', params)
}
// 编辑权限
export const editPermission = (params) => {
    return postBodyRequest('/manage/permission/edit', params)
}
// 删除权限
export const deletePermission = (params) => {
    return postRequest('/manage/permission/delByIds', params)
}
// 搜索权限
export const searchPermission = (params) => {
    return getRequest('/manage/permission/search', params)
}



// 获取全部字典
export const getAllDictList = (params) => {
    return getRequest('/manage/dict/getAll', params)
}
// 添加字典
export const addDict = (params) => {
    return postBodyRequest('/manage/dict/add', params)
}
// 编辑字典
export const editDict = (params) => {
    return postBodyRequest('/manage/dict/edit', params)
}
// 删除字典
export const deleteDict = (params) => {
    return postRequest('/manage/dict/delByIds', params)
}
// 搜索字典
export const searchDict = (params) => {
    return getRequest('/manage/dict/search', params)
}
// 获取全部字典数据
export const getAllDictDataList = (params) => {
    return postBodyRequest('/manage/dict/data/getByCondition', params)
}
// 添加字典数据
export const addDictData = (params) => {
    return postBodyRequest('/manage/dict/data/add', params)
}
// 编辑字典数据
export const editDictData = (params) => {
    return postBodyRequest('/manage/dict/data/edit', params)
}
// 删除字典数据
export const deleteData = (params) => {
    return postRequest('/manage/dict/data/delByIds', params)
}
// 通过类型获取字典数据
export const getDictDataByType = (type, params) => {
    return getRequest(`/common/dict/getDataByType/${type}`, params)
}



// 分页获取日志数据
export const getLogListData = (params) => {
    return getRequest('/manage/log/getAllByPage', params)
}
// 删除日志
export const deleteLog = (params) => {
    return postRequest('/manage/log/delByIds', params)
}
// 清空日志
export const deleteAllLog = (params) => {
    return postRequest('/manage/log/delAll', params)
}


// 分页获取Redis数据
export const getRedisData = (params) => {
    return getRequest('/redis/getAllByPage', params)
}
// 通过key获取Redis信息
export const getRedisByKey = (key, params) => {
    return getRequest(`/redis/getByKey/${key}`, params)
}
// 获取Redis键值数量
export const getRedisKeySize = (params) => {
    return getRequest('/redis/getKeySize', params)
}
// 获取Redis内存
export const getRedisMemory = (params) => {
    return getRequest('/redis/getMemory', params)
}
// 获取Redis信息
export const getRedisInfo = (params) => {
    return getRequest('/redis/info', params)
}
// 添加编辑Redis
export const saveRedis = (params) => {
    return postRequest('/redis/save', params)
}
// 删除Redis
export const deleteRedis = (params) => {
    return postRequest('/redis/delByKeys', params)
}
// 清空Redis
export const deleteAllRedis = (params) => {
    return postRequest('/redis/delAll', params)
}



// 分页获取定时任务数据
export const getQuartzListData = (params) => {
    return getRequest('/quartzJob/getAllByPage', params)
}
// 添加定时任务
export const addQuartz = (params) => {
    return postBodyRequest('/quartzJob/add', params)
}
// 编辑定时任务
export const editQuartz = (params) => {
    return postBodyRequest('/quartzJob/edit', params)
}
// 暂停定时任务
export const pauseQuartz = (params) => {
    return postRequest('/quartzJob/pause', params)
}
// 恢复定时任务
export const resumeQuartz = (params) => {
    return postRequest('/quartzJob/resume', params)
}
// 删除定时任务
export const deleteQuartz = (params) => {
    return postRequest('/quartzJob/delByIds', params)
}



// 分页获取消息数据
export const getMessageData = (params) => {
    return postBodyRequest('/manage/message/getByCondition', params)
}
// 获取单个消息详情
export const getMessageDataById = (id, params) => {
    return getRequest(`/manage/message/get/${id}`, params)
}
// 添加消息
export const addMessage = (params) => {
    return postBodyRequest('/manage/message/add', params)
}
// 编辑消息
export const editMessage = (params) => {
    return postBodyRequest('/manage/message/edit', params)
}
// 删除消息
export const deleteMessage = (params) => {
    return postRequest('/manage/message/delByIds', params)
}
// 分页获取消息推送数据
export const getMessageSendData = (params) => {
    return postBodyRequest('/manage/messageSend/getByCondition', params)
}
// 编辑发送消息
export const editMessageSend = (params) => {
    return postBodyRequest('/manage/messageSend/edit', params)
}
// 删除发送消息
export const deleteMessageSend = (params) => {
    return postRequest('/manage/messageSend/delByIds', params)
}
// 批量处理消息
export const allMessageSend = (type, params) => {
    return getRequest(`/manage/messageSend/all/${type}`, params)
}
// 获取当前系统nats频道Id
export const getMsgCenterChannelId = (params) => {
    return getRequest('/manage/messageSend/getMsgCenterChannelId', params)
}

// 分页获取数据
export const getTaskManageList = (params) => {
    return postBodyRequest('/manage/task/getByCondition', params)
}
// 添加
export const addTaskManage = (params) => {
    return postBodyRequest('/manage/task/add', params)
}
// 编辑
export const editTaskManage = (params) => {
    return postBodyRequest('/manage/task/edit', params)
}
// 删除
export const deleteTaskManage = (params) => {
    return postRequest('/manage/task/delByIds', params)
}


// 分页获取文件数据
export const getFileListData = (params) => {
    return postBodyRequest('/file/getByCondition', params)
}
// 复制文件
export const copyFile = (params) => {
    return postRequest('/file/copy', params)
}
// 重命名文件
export const renameFile = (params) => {
    return postRequest('/file/rename', params)
}
// 删除文件
export const deleteFile = (params) => {
    return postRequest('/file/delete', params)
}
// 下载文件
export const aliDownloadFile = (fKey, params) => {
    return getRequest(`/file/ali/download/${fKey}`, params)
}


// 检查oss配置
export const checkOssSet = (params) => {
    return getRequest('/manage/setting/oss/check', params)
}
// 检查oss配置
export const checkSmsSet = (params) => {
    return getRequest('/manage/setting/sms/check', params)
}
// 获取oss配置
export const getOssSet = (serviceName, params) => {
    return getRequest(`/manage/setting/oss/${serviceName}`, params)
}
// 编辑oss配置
export const editOssSet = (params) => {
    return postRequest('/manage/setting/oss/set', params)
}
// 获取sms配置
export const getSmsSet = (serviceName, params) => {
    return getRequest(`/manage/setting/sms/${serviceName}`, params)
}
// 获取sms模板code
export const getSmsTemplateCode = (serviceName, type, params) => {
    return getRequest(`/manage/setting/sms/templateCode/${serviceName}/${type}`, params)
}
// 编辑sms配置
export const editSmsSet = (params) => {
    return postRequest('/manage/setting/sms/set', params)
}
// 获取email配置
export const getEmailSet = (serviceName, params) => {
    return getRequest('/manage/setting/email', params)
}
// 编辑email配置
export const editEmailSet = (params) => {
    return postRequest('/manage/setting/email/set', params)
}
// 获取vaptcha配置
export const getVaptchaSet = (params) => {
    return getRequest('/manage/setting/vaptcha', params)
}
// 编辑vaptcha配置
export const editVaptchaSet = (params) => {
    return postRequest('/manage/setting/vaptcha/set', params)
}
// 获取other配置
export const getOtherSet = (params) => {
    return getRequest('/manage/setting/other', params)
}
// 编辑other配置
export const editOtherSet = (params) => {
    return postRequest('/manage/setting/other/set', params)
}
// 获取notice配置
export const getNoticeSet = (params) => {
    return getRequest('/manage/setting/notice', params)
}
// 编辑notice配置
export const editNoticeSet = (params) => {
    return postRequest('/manage/setting/notice/set', params)
}
// 获取autoChat配置
export const getAutoChatSet = (params) => {
    return getRequest('/manage/setting/autoChat', params)
}
// 编辑autoChat配置
export const editAutoChatSet = (params) => {
    return postRequest('/manage/setting/autoChat/set', params)
}
// 查看私密配置
export const seeSecretSet = (settingName, params) => {
    return getRequest(`/manage/setting/seeSecret/${settingName}`, params)
}



// 表格生成
export const generateTable = (name, rowNum, params) => {
    return postBodyRequest(`/manage/generate/table/${name}/${rowNum}`, params)
}
// 树生成
export const generateTree = (name, rowNum, enableTable, params) => {
    return postBodyRequest(`/manage/generate/tree/${name}/${rowNum}/${enableTable}`, params)
}
// 通过实体类生成Vue代码Json数据
export const getEntityData = (params) => {
    return getRequest('/manage/generate/getEntityData', params)
}



// 分页获取禁用词
export const getStopWordData = (params) => {
    return postBodyRequest('/manage/stopWord/getByCondition', params)
}
// 添加禁用词
export const addStopWord = (params) => {
    return postBodyRequest('/manage/stopWord/save', params)
}
// 编辑禁用词
export const editStopWord = (params) => {
    return postBodyRequest('/manage/stopWord/edit', params)
}
// 删除禁用词
export const deleteStopWord = (params) => {
    return postRequest('/manage/stopWord/delByIds', params)
}

// base64上传
export const base64Upload = (params) => {
    return postRequest('/common/upload/file', params)
}



// Catalog获取一级数据
export const initCatalog = (params) => {
    return getRequest('/manage/catalog/getByParentId/0', params)
}
// Catalog加载子级数据
export const loadCatalog = (id, params) => {
    return getRequest('/manage/catalog/getByParentId/' + id, params)
}
// Catalog添加
export const addCatalog = (params) => {
    return postBodyRequest('/manage/catalog/add', params)
}
// Catalog编辑
export const editCatalog = (params) => {
    return postBodyRequest('/manage/catalog/edit', params)
}
// Catalog删除
export const deleteCatalog = (params) => {
    return postRequest('/manage/catalog/delByIds/', params)
}
// Catalog搜索
export const searchCatalog = (params) => {
    return getRequest('/manage/catalog/search', params)
}


// Archive分页获取数据
export const getArchiveList = (params) => {
    return postBodyRequest('/manage/archive/getByCondition', params)
}
// Archive添加
export const addArchive = (params) => {
    return postBodyRequest('/manage/archive/add', params)
}
// Archive编辑
export const editArchive = (params) => {
    return postBodyRequest('/manage/archive/edit', params)
}
// Archive删除
export const deleteArchive = (params) => {
    return postRequest('/manage/archive/delByIds', params)
}


// 抽奖中心获取所有活动
export const getDrawActionList = (params) => {
    return getRequest('/manage/drawcenter/action/getAll', params)
}
// 抽奖中心获取活动
export const getDrawAction = (id) => {
    return getRequest('/manage/drawcenter/action/getById/' + id)
}
// 抽奖中心添加活动
export const addDrawAction = (params) => {
    return postBodyRequest('/manage/drawcenter/action/add', params)
}
// 抽奖中心编辑活动
export const editDrawAction = (params) => {
    return postBodyRequest('/manage/drawcenter/action/edit', params)
}
// 抽奖中心删除活动
export const deleteDrawAction = (params) => {
    return postRequest('/manage/drawcenter/action/delByIds', params)
}
// 抽奖中心获取活动中奖用户
export const getDrawActionWinnerList = (params) => {
    return getRequest('/manage/drawcenter/action/winner', params)
}

// 抽奖中心获取所有活动
export const getDrawGiftList = (params) => {
    return getRequest('/manage/drawcenter/gift/getAll', params)
}
// 抽奖中心添加活动
export const addDrawGift = (params) => {
    return postBodyRequest('/manage/drawcenter/gift/add', params)
}
// 抽奖中心编辑活动
export const editDrawGift = (params) => {
    return postBodyRequest('/manage/drawcenter/gift/edit', params)
}
// 抽奖中心删除活动
export const deleteDrawGift = (params) => {
    return postRequest('/manage/drawcenter/gift/delByIds', params)
}
// 抽奖中心获取用户获得的奖品
export const getDrawPlayerGifts = (params) => {
    return getRequest('/manage/drawcenter/gift/byplayer', params)
}

// LogManage分页获取数据
export const getLogManageList = (params) => {
    return postBodyRequest('/manage/log/getByCondition', params)
}
// LogManage添加
export const addLogManage = (params) => {
    return postBodyRequest('/manage/log/add', params)
}
// LogManage编辑
export const editLogManage = (params) => {
    return postBodyRequest('/manage/log/edit', params)
}
// LogManage删除
export const deleteLogManage = (params) => {
    return postRequest('/manage/log/delByIds', params)
}
//===============================================================================

// Demo分页获取数据
export const getDemoList = (params) => {
    return postBodyRequest('/business/demo/getByCondition', params)
}
// Demo添加
export const addDemo = (params) => {
    return postBodyRequest('/business/demo/add', params)
}
// Demo编辑
export const editDemo = (params) => {
    return postBodyRequest('/business/demo/edit', params)
}
// Demo删除
export const deleteDemo = (params) => {
    return postRequest('/business/demo/delByIds', params)
}


// Product分页获取数据
export const getProductList = (params) => {
    return postBodyRequest('/business/product/getByCondition', params)
}
// Product添加
export const addProduct = (params) => {
    return postBodyRequest('/business/product/add', params)
}
// Product编辑
export const editProduct = (params) => {
    return postBodyRequest('/business/product/edit', params)
}
// Product删除
export const deleteProduct = (params) => {
    return postRequest('/business/product/delByIds', params)
}

// Productlog分页获取数据
export const getProductlogList = (params) => {
    return postBodyRequest('/business/productlog/getByCondition', params)
}
// Productlog添加
export const addProductlog = (params) => {
    return postBodyRequest('/business/productlog/add', params)
}
// Productlog编辑
export const editProductlog = (params) => {
    return postBodyRequest('/business/productlog/edit', params)
}
// Productlog删除
export const deleteProductlog = (params) => {
    return postRequest('/business/productlog/delByIds', params)
}


// Takepoint分页获取数据
export const getTakepointList = (params) => {
    return postBodyRequest('/business/takepoint/getByCondition', params)
}
// Takepoint添加
export const addTakepoint = (params) => {
    return postBodyRequest('/business/takepoint/add', params)
}
// Takepoint编辑
export const editTakepoint = (params) => {
    return postBodyRequest('/business/takepoint/edit', params)
}
// Takepoint删除
export const deleteTakepoint = (params) => {
    return postRequest('/business/takepoint/delByIds', params)
}


// Pressrelease分页获取数据
export const getPressreleaseList = (params) => {
    return postBodyRequest('/business/pressrelease/getByCondition', params)
}
// Pressrelease添加
export const addPressrelease = (params) => {
    return postBodyRequest('/business/pressrelease/add', params)
}
// Pressrelease编辑
export const editPressrelease = (params) => {
    return postBodyRequest('/business/pressrelease/edit', params)
}
// Pressrelease删除
export const deletePressrelease = (params) => {
    return postRequest('/business/pressrelease/delByIds', params)
}

// Diskresources分页获取数据
export const getDiskresourcesList = (params) => {
    return postBodyRequest('/business/diskresources/getByCondition', params)
}
// Diskresources添加
export const addDiskresources = (params) => {
    return postBodyRequest('/business/diskresources/add', params)
}
// Diskresources编辑
export const editDiskresources = (params) => {
    return postBodyRequest('/business/diskresources/edit', params)
}
// Diskresources删除
export const deleteDiskresources = (params) => {
    return postRequest('/business/diskresources/delByIds', params)
}


// Account分页获取数据
export const getAccountList = (params) => {
    return postBodyRequest('/business/account/getByCondition', params)
}
// Account添加
export const addAccount = (params) => {
    return postBodyRequest('/business/account/add', params)
}
// Account编辑
export const editAccount = (params) => {
    return postBodyRequest('/business/account/edit', params)
}
// Account删除
export const deleteAccount = (params) => {
    return postRequest('/business/account/delByIds', params)
}